<template>
    <BaseCard
        :entry="entry"
        :is-inline="isInline"
        class="training-card"
    >
        <template #meta>
            <EntryMetadata
                :entry="entry"
                :should-link-dossiers="false"
                :show-post-date="false"
                :show-dossier-dropdown="false"
            >
                <template #pre>
                    <BaseTag>Opleiding</BaseTag>
                </template>
            </EntryMetadata>
        </template>
    </BaseCard>
</template>

<script setup>
import BaseCard from '@/components/BaseCard/BaseCard.vue';
import BaseTag from '@/components/BaseTag/BaseTag.vue';

defineProps({
    entry: {
        type: Object,
        required: true,
        default: null,
    },
    isInline: {
        type: Boolean,
        default: false,
    }
});
</script>

<style lang="less" src="./TrainingCard.less" />
